<template>
    <div class="apaympro-container">

        <!-- Loader -->
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="bars"
            :opacity="1"
        ></loading>


        <!-- Top Bar Component --> 
        <top-bar></top-bar> 
        <!-- Top Bar Component -->

        <div class="apaympro-wrapper animated fadeIn container">
            <div class="apaympro-page">
                <div class="apaympro-header">
                    <div class="page-info mt-3 mb-3">
                        <img src="../assets/apaym-pay.png" width="150" alt="Logo APaym Pro" />
                    </div>
                    <div class="mt-5">
                        <div class="mt-4 mb-5" v-if="merchantList.length > 0">
                            <ul 
                                class="list-unstyled media-list-divider"
                                :style=" merchantList.length > 5 ? 'height: 400px; overflow: scroll-y;' : 'height: auto; overflow: scroll-y;'"
                            >
                                <li 
                                    class="media py-3 mb-3"
                                    v-for="merchant in merchantList"
                                    :key="merchant.id"
                                >
                                
                                    <img src="../assets/img/building_Artboard.png" v-if="merchant.type !== 'SP' && merchant.partner !== '2'" class="fas fa-building mr-3 rounded-circle align-self-center" width="45" style="margin-top: 6px;" />
                                    <img src="../assets/img/poeple_Artboard.png" v-if="merchant.type == 'SP' && merchant.partner !== '2'" class="fas fa-building mr-3 rounded-circle align-self-center" width="45" style="margin-top: 6px;" />
                                    <img src="../assets/img/yango.png" v-if="merchant.partner == '2'" class="fas fa-building mr-3 rounded-circle align-self-center" width="45" style="margin-top: 6px;" />

                                    <div class="media-body">
                                        <div class="d-flex justify-content-between" style="align-items: center;">
                                            <div @click="loadPage(merchant)" style="cursor:pointer;" >
                                                <h6 class="mb-1 font-16 text-left" style="text-transform: uppercase;">
                                                    {{ merchant.merchantName }} <img src="../assets/img/badge-check.png" v-if="merchant.verified == '1'" width="15" style="margin-top: -3px;" />
                                                </h6>
                                                <p class="text-muted text-left font-12">
                                                    https://pay.apaym.com/{{ merchant.url }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer APaym Secured Badge -->
        <footer-item></footer-item>
        <!-- Footer APaym Secured Badge -->

    </div>
</template>


<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { sweetAlert } from "../constants/functions";
    import axios from 'axios';
    import { apiUrl } from '../constants/config';
    import TopBar from '../components/TopBar' ;
    import FooterItem from '../components/FooterItem';

    export default {
        components: {
            Loading,
            'top-bar': TopBar,
            'footer-item': FooterItem,
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                url_data: {
                    numero: ""
                },
                merchantList: []
            }
        },
        methods: {

            onCancel() {
                console.log('Loader dismiss') ;
            },

            // Get information of payment page
            getBusiness(numero) {

                this.isLoading = true;

                let dataSend = new FormData();
                dataSend.append("value", numero); 

                axios.post(apiUrl + 'api/payment-merchant/show-link',
                    dataSend
                ).then(response => {

                    this.isLoading = false;
                    this.merchantList = [] ;

                    if ( response.data.code == 1 ) {

                        for ( let [key, rep] of Object.entries(response.data.merchant) ) {

                            this.merchantList.push({
                                id: key,
                                merchantName: rep.merchantName,
                                url: rep.url,
                                type: rep.type,
                                verified: rep.verified,
                                partner: rep.partenaire
                            }) ;
                        }

                        return false;
                    }

                }).catch(error => {

                    this.isLoading = false;
                    console.log(error);
                    sweetAlert(this.$swal, true, 'top-end', 'error', 'Erreur', "Une erreur est survenue, veuillez réessayer plus tard");
                    
                });

            },

            loadPage(merchant){
                window.location.href = "https://pay.apaym.com/"+ merchant.url ;
            },
            
        },

        mounted() {
            
            this.url_data.numero = this.$route.params.numero;
           
            console.log(this.url_data.numero)
            if ( !this.url_data.numero || this.url_data.numero == "" ) {
                
                localStorage.clear() ;
                sessionStorage.clear() ;
                window.location.href = "https://pay.apaym.com" ;
                return false;
            } 

            this.getBusiness(this.url_data.numero);
        },
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

   
  
</style>
